import React from 'react';
import Select from 'react-select';
import { countries } from '../AreaCode';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    groupStyles: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    groupBadgeStyles: {
        backgroundColor: '#EBECF0',
        borderRadius: '2em',
        color: '#172B4D',
        fontWeight: 'normal',
        lineHeight: '1',
        minWidth: 1,
        padding: '0.16666666666667em 0.5em',
        textAlign: 'center',
    },
    root: {
        background: "blue",
        color: 'black'
      },
      whiteColor: {
        background: "blue",
        color: "black"
      },
      select: {
        color: "black",
      },
      icon: { color: "black" },
      label: { color: "black" },
}))

const AreaCodeSelector = ({ onChange }) => {
    // https://react-select.com/styles
    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          color: state.isSelected ? 'black' : 'gray',
          background: state.isSelected ? 'white' : '',
        }),
        singleValue: (provided, state) => {
          const opacity = 1;
          return { ...provided, opacity};
        }
      }


    const classes = useStyles();
    const formatGroupLabel = countries => (
        <div className={classes.groupStyles}>
            <span>{countries.label}</span>
            <span className={classes.groupBadgeStyles}>
                {Object.keys(countries).length}
            </span>
        </div>
    );
    return (<>
        <Select
            styles={customStyles}
            defaultValue={countries[226]}
            options={countries}
            formatGroupLabel={formatGroupLabel}
            onChange={onChange}
        />
    </>)
}
export default AreaCodeSelector;