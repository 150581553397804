// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
// TODO: Using en_us if we support multiple languages
export const countries_en_us = [
    { code: 'AD', label: '376 Andorra', value: '376' },
    { code: 'AE', label: '971 United Arab Emirates', value: '971' },
    { code: 'AF', label: '93 Afghanistan', value: '93' },
    { code: 'AG', label: '1-268 Antigua and Barbuda', value: '1268' },
    { code: 'AI', label: '1-264 Anguilla', value: '1264' },
    { code: 'AL', label: '355 Albania', value: '355' },
    { code: 'AM', label: '374 Armenia', value: '374' },
    { code: 'AO', label: '244 Angola', value: '244' },
    { code: 'AQ', label: '672 Antarctica', value: '672' },
    { code: 'AR', label: '54 Argentina', value: '54' },
    { code: 'AS', label: '1-684 American Samoa', value: '1684' },
    { code: 'AT', label: '43 Austria', value: '43' },
    { code: 'AU', label: '61 Australia', value: '61', suggested: true },
    { code: 'AW', label: '297 Aruba', value: '297' },
    { code: 'AX', label: '358 Alland Islands', value: '358' },
    { code: 'AZ', label: '994 Azerbaijan', value: '994' },
    { code: 'BA', label: '387 Bosnia and Herzegovina', value: '387' },
    { code: 'BB', label: '1-246 Barbados', value: '1246' },
    { code: 'BD', label: '880 Bangladesh', value: '880' },
    { code: 'BE', label: '32 Belgium', value: '32' },
    { code: 'BF', label: '226 Burkina Faso', value: '226' },
    { code: 'BG', label: '359 Bulgaria', value: '359' },
    { code: 'BH', label: '973 Bahrain', value: '973' },
    { code: 'BI', label: '257 Burundi', value: '257' },
    { code: 'BJ', label: '229 Benin', value: '229' },
    { code: 'BL', label: '590 Saint Barthelemy', value: '590' },
    { code: 'BM', label: '1-441 Bermuda', value: '1441' },
    { code: 'BN', label: '673 Brunei Darussalam', value: '673' },
    { code: 'BO', label: '591 Bolivia', value: '591' },
    { code: 'BR', label: '55 Brazil', value: '55' },
    { code: 'BS', label: '1-242 Bahamas', value: '1242' },
    { code: 'BT', label: '975 Bhutan', value: '975' },
    { code: 'BV', label: '47 Bouvet Island', value: '47' },
    { code: 'BW', label: '267 Botswana', value: '267' },
    { code: 'BY', label: '375 Belarus', value: '375' },
    { code: 'BZ', label: '501 Belize', value: '501' },
    { code: 'CA', label: '1 Canada', value: '1', suggested: true },
    { code: 'CC', label: '61 Cocos (Keeling) Islands', value: '61' },
    { code: 'CD', label: '243 Congo, Democratic Republic of the', value: '243' },
    { code: 'CF', label: '236 Central African Republic', value: '236' },
    { code: 'CG', label: '242 Congo, Republic of the', value: '242' },
    { code: 'CH', label: '41 Switzerland', value: '41' },
    { code: 'CI', label: "225 Cote d'Ivoire", value: '225' },
    { code: 'CK', label: '682 Cook Islands', value: '682' },
    { code: 'CL', label: '56 Chile', value: '56' },
    { code: 'CM', label: '237 Cameroon', value: '237' },
    { code: 'CN', label: '86 China', value: '86' },
    { code: 'CO', label: '57 Colombia', value: '57' },
    { code: 'CR', label: '506 Costa Rica', value: '506' },
    { code: 'CU', label: '53 Cuba', value: '53' },
    { code: 'CV', label: '238 Cape Verde', value: '238' },
    { code: 'CW', label: '599 Curacao', value: '599' },
    { code: 'CX', label: '61 Christmas Island', value: '61' },
    { code: 'CY', label: '357 Cyprus', value: '357' },
    { code: 'CZ', label: '420 Czech Republic', value: '420' },
    { code: 'DE', label: '49 Germany', value: '49', suggested: true },
    { code: 'DJ', label: '253 Djibouti', value: '253' },
    { code: 'DK', label: '45 Denmark', value: '45' },
    { code: 'DM', label: '1-767 Dominica', value: '1767' },
    { code: 'DO', label: '1-809 Dominican Republic', value: '1809' },
    { code: 'DZ', label: '213 Algeria', value: '213' },
    { code: 'EC', label: '593 Ecuador', value: '593' },
    { code: 'EE', label: '372 Estonia', value: '372' },
    { code: 'EG', label: '20 Egypt', value: '20' },
    { code: 'EH', label: '212 Western Sahara', value: '212' },
    { code: 'ER', label: '291 Eritrea', value: '291' },
    { code: 'ES', label: '34 Spain', value: '34' },
    { code: 'ET', label: '251 Ethiopia', value: '251' },
    { code: 'FI', label: '358 Finland', value: '358' },
    { code: 'FJ', label: '679 Fiji', value: '679' },
    { code: 'FK', label: '500 Falkland Islands (Malvinas)', value: '500' },
    { code: 'FM', label: '691 Micronesia, Federated States of', value: '691' },
    { code: 'FO', label: '298 Faroe Islands', value: '298' },
    { code: 'FR', label: '33 France', value: '33', suggested: true },
    { code: 'GA', label: '241 Gabon', value: '241' },
    { code: 'GB', label: '44 United Kingdom', value: '44' },
    { code: 'GD', label: '1-473 Grenada', value: '1473' },
    { code: 'GE', label: '995 Georgia', value: '995' },
    { code: 'GF', label: '594 French Guiana', value: '594' },
    { code: 'GG', label: '44 Guernsey', value: '44' },
    { code: 'GH', label: '233 Ghana', value: '233' },
    { code: 'GI', label: '350 Gibraltar', value: '350' },
    { code: 'GL', label: '299 Greenland', value: '299' },
    { code: 'GM', label: '220 Gambia', value: '220' },
    { code: 'GN', label: '224 Guinea', value: '224' },
    { code: 'GP', label: '590 Guadeloupe', value: '590' },
    { code: 'GQ', label: '240 Equatorial Guinea', value: '240' },
    { code: 'GR', label: '30 Greece', value: '30' },
    { code: 'GS', label: '500 South Georgia and the South Sandwich Islands', value: '500' },
    { code: 'GT', label: '502 Guatemala', value: '502' },
    { code: 'GU', label: '1-671 Guam', value: '1671' },
    { code: 'GW', label: '245 Guinea-Bissau', value: '245' },
    { code: 'GY', label: '592 Guyana', value: '592' },
    { code: 'HK', label: '852 Hong Kong', value: '852' },
    { code: 'HM', label: '672 Heard Island and McDonald Islands', value: '672' },
    { code: 'HN', label: '504 Honduras', value: '504' },
    { code: 'HR', label: '385 Croatia', value: '385' },
    { code: 'HT', label: '509 Haiti', value: '509' },
    { code: 'HU', label: '36 Hungary', value: '36' },
    { code: 'ID', label: '62 Indonesia', value: '62' },
    { code: 'IE', label: '353 Ireland', value: '353' },
    { code: 'IL', label: '972 Israel', value: '972' },
    { code: 'IM', label: '44 Isle of Man', value: '44' },
    { code: 'IN', label: '91 India', value: '91' },
    { code: 'IO', label: '246 British Indian Ocean Territory', value: '246' },
    { code: 'IQ', label: '964 Iraq', value: '964' },
    { code: 'IR', label: '98 Iran, Islamic Republic of', value: '98' },
    { code: 'IS', label: '354 Iceland', value: '354' },
    { code: 'IT', label: '39 Italy', value: '39' },
    { code: 'JE', label: '44 Jersey', value: '44' },
    { code: 'JM', label: '1-876 Jamaica', value: '1876' },
    { code: 'JO', label: '962 Jordan', value: '962' },
    { code: 'JP', label: '81 Japan', value: '81', suggested: true },
    { code: 'KE', label: '254 Kenya', value: '254' },
    { code: 'KG', label: '996 Kyrgyzstan', value: '996' },
    { code: 'KH', label: '855 Cambodia', value: '855' },
    { code: 'KI', label: '686 Kiribati', value: '686' },
    { code: 'KM', label: '269 Comoros', value: '269' },
    { code: 'KN', label: '1-869 Saint Kitts and Nevis', value: '1869' },
    { code: 'KP', label: "850 Korea, Democratic People's Republic of", value: '850' },
    { code: 'KR', label: '82 Korea, Republic of', value: '82' },
    { code: 'KW', label: '965 Kuwait', value: '965' },
    { code: 'KY', label: '1-345 Cayman Islands', value: '1345' },
    { code: 'KZ', label: '7 Kazakhstan', value: '7' },
    { code: 'LA', label: "856 Lao People's Democratic Republic", value: '856' },
    { code: 'LB', label: '961 Lebanon', value: '961' },
    { code: 'LC', label: '1-758 Saint Lucia', value: '1758' },
    { code: 'LI', label: '423 Liechtenstein', value: '423' },
    { code: 'LK', label: '94 Sri Lanka', value: '94' },
    { code: 'LR', label: '231 Liberia', value: '231' },
    { code: 'LS', label: '266 Lesotho', value: '266' },
    { code: 'LT', label: '370 Lithuania', value: '370' },
    { code: 'LU', label: '352 Luxembourg', value: '352' },
    { code: 'LV', label: '371 Latvia', value: '371' },
    { code: 'LY', label: '218 Libya', value: '218' },
    { code: 'MA', label: '212 Morocco', value: '212' },
    { code: 'MC', label: '377 Monaco', value: '377' },
    { code: 'MD', label: '373 Moldova, Republic of', value: '373' },
    { code: 'ME', label: '382 Montenegro', value: '382' },
    { code: 'MF', label: '590 Saint Martin (French part)', value: '590' },
    { code: 'MG', label: '261 Madagascar', value: '261' },
    { code: 'MH', label: '692 Marshall Islands', value: '692' },
    { code: 'MK', label: '389 Macedonia, the Former Yugoslav Republic of', value: '389' },
    { code: 'ML', label: '223 Mali', value: '223' },
    { code: 'MM', label: '95 Myanmar', value: '95' },
    { code: 'MN', label: '976 Mongolia', value: '976' },
    { code: 'MO', label: '853 Macao', value: '853' },
    { code: 'MP', label: '1-670 Northern Mariana Islands', value: '1670' },
    { code: 'MQ', label: '596 Martinique', value: '596' },
    { code: 'MR', label: '222 Mauritania', value: '222' },
    { code: 'MS', label: '1-664 Montserrat', value: '1664' },
    { code: 'MT', label: '356 Malta', value: '356' },
    { code: 'MU', label: '230 Mauritius', value: '230' },
    { code: 'MV', label: '960 Maldives', value: '960' },
    { code: 'MW', label: '265 Malawi', value: '265' },
    { code: 'MX', label: '52 Mexico', value: '52' },
    { code: 'MY', label: '60 Malaysia', value: '60' },
    { code: 'MZ', label: '258 Mozambique', value: '258' },
    { code: 'NA', label: '264 Namibia', value: '264' },
    { code: 'NC', label: '687 New Caledonia', value: '687' },
    { code: 'NE', label: '227 Niger', value: '227' },
    { code: 'NF', label: '672 Norfolk Island', value: '672' },
    { code: 'NG', label: '234 Nigeria', value: '234' },
    { code: 'NI', label: '505 Nicaragua', value: '505' },
    { code: 'NL', label: '31 Netherlands', value: '31' },
    { code: 'NO', label: '47 Norway', value: '47' },
    { code: 'NP', label: '977 Nepal', value: '977' },
    { code: 'NR', label: '674 Nauru', value: '674' },
    { code: 'NU', label: '683 Niue', value: '683' },
    { code: 'NZ', label: '64 New Zealand', value: '64' },
    { code: 'OM', label: '968 Oman', value: '968' },
    { code: 'PA', label: '507 Panama', value: '507' },
    { code: 'PE', label: '51 Peru', value: '51' },
    { code: 'PF', label: '689 French Polynesia', value: '689' },
    { code: 'PG', label: '675 Papua New Guinea', value: '675' },
    { code: 'PH', label: '63 Philippines', value: '63' },
    { code: 'PK', label: '92 Pakistan', value: '92' },
    { code: 'PL', label: '48 Poland', value: '48' },
    { code: 'PM', label: '508 Saint Pierre and Miquelon', value: '508' },
    { code: 'PN', label: '870 Pitcairn', value: '870' },
    { code: 'PR', label: '1 Puerto Rico', value: '1' },
    { code: 'PS', label: '970 Palestine, State of', value: '970' },
    { code: 'PT', label: '351 Portugal', value: '351' },
    { code: 'PW', label: '680 Palau', value: '680' },
    { code: 'PY', label: '595 Paraguay', value: '595' },
    { code: 'QA', label: '974 Qatar', value: '974' },
    { code: 'RE', label: '262 Reunion', value: '262' },
    { code: 'RO', label: '40 Romania', value: '40' },
    { code: 'RS', label: '381 Serbia', value: '381' },
    { code: 'RU', label: '7 Russian Federation', value: '7' },
    { code: 'RW', label: '250 Rwanda', value: '250' },
    { code: 'SA', label: '966 Saudi Arabia', value: '966' },
    { code: 'SB', label: '677 Solomon Islands', value: '677' },
    { code: 'SC', label: '248 Seychelles', value: '248' },
    { code: 'SD', label: '249 Sudan', value: '249' },
    { code: 'SE', label: '46 Sweden', value: '46' },
    { code: 'SG', label: '65 Singapore', value: '65' },
    { code: 'SH', label: '290 Saint Helena', value: '290' },
    { code: 'SI', label: '386 Slovenia', value: '386' },
    { code: 'SJ', label: '47 Svalbard and Jan Mayen', value: '47' },
    { code: 'SK', label: '421 Slovakia', value: '421' },
    { code: 'SL', label: '232 Sierra Leone', value: '232' },
    { code: 'SM', label: '378 San Marino', value: '378' },
    { code: 'SN', label: '221 Senegal', value: '221' },
    { code: 'SO', label: '252 Somalia', value: '252' },
    { code: 'SR', label: '597 Suriname', value: '597' },
    { code: 'SS', label: '211 South Sudan', value: '211' },
    { code: 'ST', label: '239 Sao Tome and Principe', value: '239' },
    { code: 'SV', label: '503 El Salvador', value: '503' },
    { code: 'SX', label: '1-721 Sint Maarten (Dutch part)', value: '1721' },
    { code: 'SY', label: '963 Syrian Arab Republic', value: '963' },
    { code: 'SZ', label: '268 Swaziland', value: '268' },
    { code: 'TC', label: '1-649 Turks and Caicos Islands', value: '1649' },
    { code: 'TD', label: '235 Chad', value: '235' },
    { code: 'TF', label: '262 French Southern Territories', value: '262' },
    { code: 'TG', label: '228 Togo', value: '228' },
    { code: 'TH', label: '66 Thailand', value: '66' },
    { code: 'TJ', label: '992 Tajikistan', value: '992' },
    { code: 'TK', label: '690 Tokelau', value: '690' },
    { code: 'TL', label: '670 Timor-Leste', value: '670' },
    { code: 'TM', label: '993 Turkmenistan', value: '993' },
    { code: 'TN', label: '216 Tunisia', value: '216' },
    { code: 'TO', label: '676 Tonga', value: '676' },
    { code: 'TR', label: '90 Turkey', value: '90' },
    { code: 'TT', label: '1-868 Trinidad and Tobago', value: '1868' },
    { code: 'TV', label: '688 Tuvalu', value: '688' },
    { code: 'TW', label: '886 Taiwan, Province of China', value: '886' },
    { code: 'TZ', label: '255 United Republic of Tanzania', value: '255' },
    { code: 'UA', label: '380 Ukraine', value: '380' },
    { code: 'UG', label: '256 Uganda', value: '256' },
    { code: 'US', label: '1 United States', value: '1', suggested: true },
    { code: 'UY', label: '598 Uruguay', value: '598' },
    { code: 'UZ', label: '998 Uzbekistan', value: '998' },
    { code: 'VA', label: '379 Holy See (Vatican City State)', value: '379' },
    { code: 'VC', label: '1-784 Saint Vincent and the Grenadines', value: '1784' },
    { code: 'VE', label: '58 Venezuela', value: '58' },
    { code: 'VG', label: '1-284 British Virgin Islands', value: '1284' },
    { code: 'VI', label: '1-340 US Virgin Islands', value: '1340' },
    { code: 'VN', label: '84 Vietnam', value: '84' },
    { code: 'VU', label: '678 Vanuatu', value: '678' },
    { code: 'WF', label: '681 Wallis and Futuna', value: '681' },
    { code: 'WS', label: '685 Samoa', value: '685' },
    { code: 'XK', label: '383 Kosovo', value: '383' },
    { code: 'YE', label: '967 Yemen', value: '967' },
    { code: 'YT', label: '262 Mayotte', value: '262' },
    { code: 'ZA', label: '27 South Africa', value: '27' },
    { code: 'ZM', label: '260 Zambia', value: '260' },
    { code: 'ZW', label: '263 Zimbabwe', value: '263' },
];

export const countries = [
    { code: 'AD', label: '376 安道爾', value: '376' },
    { code: 'AE', label: '971 阿聯', value: '971' },
    { code: 'AF', label: '93 阿富汗', value: '93' },
    { code: 'AG', label: '1-268 安地卡及巴布達', value: '1268' },
    { code: 'AI', label: '1-264 安圭拉', value: '1264' },
    { code: 'AL', label: '355 阿爾巴尼亞', value: '355' },
    { code: 'AM', label: '374 亞美尼亞', value: '374' },
    { code: 'AO', label: '244 安哥拉', value: '244' },
    { code: 'AQ', label: '672 Antarctica 澳洲海外領地', value: '672' },
    { code: 'AR', label: '54 阿根廷', value: '54' },
    { code: 'AS', label: '1-684 美屬薩摩亞', value: '1684' },
    { code: 'AT', label: '43 奧地利', value: '43' },
    { code: 'AU', label: '61 澳洲', value: '61', suggested: true },
    { code: 'AW', label: '297 阿魯巴', value: '297' },
    { code: 'AX', label: '358-18 奧蘭', value: '35818' },
    { code: 'AZ', label: '994 亞塞拜然', value: '994' },
    { code: 'BA', label: '387 波赫', value: '387' },
    { code: 'BB', label: '1-246 巴貝多', value: '1246' },
    { code: 'BD', label: '880 孟加拉', value: '880' },
    { code: 'BE', label: '32 比利時', value: '32' },
    { code: 'BF', label: '226 布吉納法索', value: '226' },
    { code: 'BG', label: '359 保加利亞', value: '359' },
    { code: 'BH', label: '973 巴林', value: '973' },
    { code: 'BI', label: '257 蒲隆地', value: '257' },
    { code: 'BJ', label: '229 貝南', value: '229' },
    { code: 'BL', label: '590 瓜地洛普', value: '590' },
    { code: 'BM', label: '1-441 百慕達', value: '1441' },
    { code: 'BN', label: '673 汶萊', value: '673' },
    { code: 'BO', label: '591 玻利維亞', value: '591' },
    { code: 'BR', label: '55 巴西', value: '55' },
    { code: 'BS', label: '1-242 巴哈馬', value: '1242' },
    { code: 'BT', label: '975 不丹', value: '975' },
    { code: 'BV', label: '47 挪威', value: '47' },
    { code: 'BW', label: '267 波札那', value: '267' },
    { code: 'BY', label: '375 白俄羅斯', value: '375' },
    { code: 'BZ', label: '501 貝里斯', value: '501' },
    { code: 'CA', label: '1 加拿大', value: '1', suggested: true },
    { code: 'CC', label: '61 科科斯（基林）群島', value: '61' },
    { code: 'CD', label: '243 剛果民主共和國', value: '243' },
    { code: 'CF', label: '236 中非', value: '236' },
    { code: 'CG', label: '242 剛果共和國', value: '242' },
    { code: 'CH', label: '41 瑞士', value: '41' },
    { code: 'CI', label: "225 象牙海岸", value: '225' },
    { code: 'CK', label: '682 庫克群島', value: '682' },
    { code: 'CL', label: '56 智利', value: '56' },
    { code: 'CM', label: '237 喀麥隆', value: '237' },
    { code: 'CN', label: '86 中國', value: '86' },
    { code: 'CO', label: '57 哥倫比亞', value: '57' },
    { code: 'CR', label: '506 哥斯大黎加', value: '506' },
    { code: 'CU', label: '53 古巴', value: '53' },
    { code: 'CV', label: '238 維德角', value: '238' },
    { code: 'CW', label: '599 荷屬安地列斯', value: '599' },
    { code: 'CX', label: '61 聖誕島', value: '61' },
    { code: 'CY', label: '357 賽普勒斯', value: '357' },
    { code: 'CZ', label: '420 捷克', value: '420' },
    { code: 'DE', label: '49 德國', value: '49', suggested: true },
    { code: 'DJ', label: '253 吉布地', value: '253' },
    { code: 'DK', label: '45 丹麥', value: '45' },
    { code: 'DM', label: '1-767 多米尼克', value: '1767' },
    { code: 'DO', label: '1-809 多明尼加', value: '1809' },
    { code: 'DZ', label: '213 阿爾及利亞', value: '213' },
    { code: 'EC', label: '593 厄瓜多', value: '593' },
    { code: 'EE', label: '372 愛沙尼亞', value: '372' },
    { code: 'EG', label: '20 埃及', value: '20' },
    { code: 'EH', label: '212 摩洛哥', value: '212' },
    { code: 'ER', label: '291 厄利垂亞', value: '291' },
    { code: 'ES', label: '34 西班牙', value: '34' },
    { code: 'ET', label: '251 衣索比亞', value: '251' },
    { code: 'FI', label: '358 芬蘭', value: '358' },
    { code: 'FJ', label: '679 斐濟', value: '679' },
    { code: 'FK', label: '500 福克蘭群島', value: '500' },
    { code: 'FM', label: '691 密克羅尼西亞聯邦', value: '691' },
    { code: 'FO', label: '298 法羅群島', value: '298' },
    { code: 'FR', label: '33 法國', value: '33', suggested: true },
    { code: 'GA', label: '241 加彭', value: '241' },
    { code: 'GB', label: '44 英國', value: '44' },
    { code: 'GD', label: '1-473 格瑞那達', value: '1473' },
    { code: 'GE', label: '995 喬治亞', value: '995' },
    { code: 'GF', label: '594 法屬圭亞那', value: '594' },
    { code: 'GG', label: '44-1534 澤西', value: '441534' },
    { code: 'GH', label: '233 迦納', value: '233' },
    { code: 'GI', label: '350 直布羅陀', value: '350' },
    { code: 'GL', label: '299 格陵蘭', value: '299' },
    { code: 'GM', label: '220 甘比亞', value: '220' },
    { code: 'GN', label: '224 幾內亞', value: '224' },
    { code: 'GP', label: '590 瓜地洛普', value: '590' },
    { code: 'GQ', label: '240 赤道幾內亞', value: '240' },
    { code: 'GR', label: '30 希臘', value: '30' },
    { code: 'GS', label: '500 南喬治亞和南桑威奇群島', value: '500' },
    { code: 'GT', label: '502 瓜地馬拉', value: '502' },
    { code: 'GU', label: '1-671 關島', value: '1671' },
    { code: 'GW', label: '245 幾內亞比索', value: '245' },
    { code: 'GY', label: '592 蓋亞那', value: '592' },
    { code: 'HK', label: '852 香港', value: '852' },
    { code: 'HM', label: '672 赫德島和麥克唐納群島', value: '672' },
    { code: 'HN', label: '504 宏都拉斯', value: '504' },
    { code: 'HR', label: '385 克羅埃西亞', value: '385' },
    { code: 'HT', label: '509 海地', value: '509' },
    { code: 'HU', label: '36 匈牙利', value: '36' },
    { code: 'ID', label: '62 印尼', value: '62' },
    { code: 'IE', label: '353 愛爾蘭', value: '353' },
    { code: 'IL', label: '972 以色列', value: '972' },
    { code: 'IM', label: '44 曼島', value: '44' },
    { code: 'IN', label: '91 印度', value: '91' },
    { code: 'IO', label: '246 英屬印度洋領地', value: '246' },
    { code: 'IQ', label: '964 伊拉克', value: '964' },
    { code: 'IR', label: '98 伊朗', value: '98' },
    { code: 'IS', label: '354 冰島', value: '354' },
    { code: 'IT', label: '39 義大利', value: '39' },
    { code: 'JE', label: '44 澤西', value: '44' },
    { code: 'JM', label: '1-876 牙買加', value: '1876' },
    { code: 'JO', label: '962 約旦', value: '962' },
    { code: 'JP', label: '81 日本', value: '81', suggested: true },
    { code: 'KE', label: '254 肯亞', value: '254' },
    { code: 'KG', label: '996 吉爾吉斯', value: '996' },
    { code: 'KH', label: '855 柬埔寨', value: '855' },
    { code: 'KI', label: '686 吉里巴斯', value: '686' },
    { code: 'KM', label: '269 葛摩', value: '269' },
    { code: 'KN', label: '1-869 聖克里斯多福及尼維斯', value: '1869' },
    { code: 'KP', label: "850 北韓", value: '850' },
    { code: 'KR', label: '82 南韓', value: '82' },
    { code: 'KW', label: '965 科威特', value: '965' },
    { code: 'KY', label: '1-345 開曼群島', value: '1345' },
    { code: 'KZ', label: '7 哈薩克', value: '7' },
    { code: 'LA', label: "856 寮國", value: '856' },
    { code: 'LB', label: '961 黎巴嫩', value: '961' },
    { code: 'LC', label: '1-758 聖露西亞', value: '1758' },
    { code: 'LI', label: '423 列支敦斯登', value: '423' },
    { code: 'LK', label: '94 斯里蘭卡', value: '94' },
    { code: 'LR', label: '231 賴比瑞亞', value: '231' },
    { code: 'LS', label: '266 賴索托', value: '266' },
    { code: 'LT', label: '370 立陶宛', value: '370' },
    { code: 'LU', label: '352 盧森堡', value: '352' },
    { code: 'LV', label: '371 拉脫維亞', value: '371' },
    { code: 'LY', label: '218 利比亞', value: '218' },
    { code: 'MA', label: '212 摩洛哥', value: '212' },
    { code: 'MC', label: '377 摩納哥', value: '377' },
    { code: 'MD', label: '373 摩爾多瓦', value: '373' },
    { code: 'ME', label: '382 蒙特內哥羅', value: '382' },
    { code: 'MF', label: '590 瓜地洛普', value: '590' },
    { code: 'MG', label: '261 馬達加斯加', value: '261' },
    { code: 'MH', label: '692 馬紹爾群島', value: '692' },
    { code: 'MK', label: '389 北馬其頓', value: '389' },
    { code: 'ML', label: '223 馬利', value: '223' },
    { code: 'MM', label: '95 緬甸', value: '95' },
    { code: 'MN', label: '976 蒙古', value: '976' },
    { code: 'MO', label: '853 澳門', value: '853' },
    { code: 'MP', label: '1-670 北馬利安納群島', value: '1670' },
    { code: 'MQ', label: '596 馬丁尼克', value: '596' },
    { code: 'MR', label: '222 茅利塔尼亞', value: '222' },
    { code: 'MS', label: '1-664 蒙哲臘', value: '1664' },
    { code: 'MT', label: '356 馬爾他', value: '356' },
    { code: 'MU', label: '230 模里西斯', value: '230' },
    { code: 'MV', label: '960 馬爾地夫', value: '960' },
    { code: 'MW', label: '265 馬拉威', value: '265' },
    { code: 'MX', label: '52 墨西哥', value: '52' },
    { code: 'MY', label: '60 馬來西亞', value: '60' },
    { code: 'MZ', label: '258 莫三比克', value: '258' },
    { code: 'NA', label: '264 納米比亞', value: '264' },
    { code: 'NC', label: '687 新喀里多尼亞', value: '687' },
    { code: 'NE', label: '227 尼日', value: '227' },
    { code: 'NF', label: '672 諾福克島', value: '672' },
    { code: 'NG', label: '234 奈及利亞', value: '234' },
    { code: 'NI', label: '505 尼加拉瓜', value: '505' },
    { code: 'NL', label: '31 荷蘭', value: '31' },
    { code: 'NO', label: '47 挪威', value: '47' },
    { code: 'NP', label: '977 尼泊爾', value: '977' },
    { code: 'NR', label: '674 諾魯', value: '674' },
    { code: 'NU', label: '683 紐埃', value: '683' },
    { code: 'NZ', label: '64 紐西蘭', value: '64' },
    { code: 'OM', label: '968 阿曼', value: '968' },
    { code: 'PA', label: '507 巴拿馬', value: '507' },
    { code: 'PE', label: '51 秘魯', value: '51' },
    { code: 'PF', label: '689 法屬玻里尼西亞', value: '689' },
    { code: 'PG', label: '675 巴布亞紐幾內亞', value: '675' },
    { code: 'PH', label: '63 菲律賓', value: '63' },
    { code: 'PK', label: '92 巴基斯坦', value: '92' },
    { code: 'PL', label: '48 波蘭', value: '48' },
    { code: 'PM', label: '508 聖皮埃與密克隆群島', value: '508' },
    { code: 'PN', label: '870 Inmarsat「SNAC」服務', value: '870' },
    { code: 'PR', label: '1 波多黎各', value: '1' },
    { code: 'PS', label: '970 巴勒斯坦', value: '970' },
    { code: 'PT', label: '351 葡萄牙', value: '351' },
    { code: 'PW', label: '680 帛琉', value: '680' },
    { code: 'PY', label: '595 巴拉圭', value: '595' },
    { code: 'QA', label: '974 卡達', value: '974' },
    { code: 'RE', label: '262 留尼旺', value: '262' },
    { code: 'RO', label: '40 羅馬尼亞', value: '40' },
    { code: 'RS', label: '381 塞爾維亞', value: '381' },
    { code: 'RU', label: '7 俄羅斯', value: '7' },
    { code: 'RW', label: '250 盧安達', value: '250' },
    { code: 'SA', label: '966 沙烏地阿拉伯', value: '966' },
    { code: 'SB', label: '677 索羅門群島', value: '677' },
    { code: 'SC', label: '248 塞席爾', value: '248' },
    { code: 'SD', label: '249 蘇丹', value: '249' },
    { code: 'SE', label: '46 瑞典', value: '46' },
    { code: 'SG', label: '65 新加坡', value: '65' },
    { code: 'SH', label: '290 聖赫勒拿', value: '290' },
    { code: 'SI', label: '386 斯洛維尼亞', value: '386' },
    { code: 'SJ', label: '47 斯瓦爾巴和揚馬延', value: '47' },
    { code: 'SK', label: '421 斯洛伐克', value: '421' },
    { code: 'SL', label: '232 獅子山', value: '232' },
    { code: 'SM', label: '378 聖馬利諾', value: '378' },
    { code: 'SN', label: '221 塞內加爾', value: '221' },
    { code: 'SO', label: '252 索馬利亞', value: '252' },
    { code: 'SR', label: '597 蘇利南', value: '597' },
    { code: 'SS', label: '211 南蘇丹', value: '211' },
    { code: 'ST', label: '239 聖多美普林西比', value: '239' },
    { code: 'SV', label: '503 薩爾瓦多', value: '503' },
    { code: 'SX', label: '1-721 荷屬聖馬丁', value: '1721' },
    { code: 'SY', label: '963 敘利亞', value: '963' },
    { code: 'SZ', label: '268 史瓦帝尼', value: '268' },
    { code: 'TC', label: '1-649 特克斯與凱科斯群島', value: '1649' },
    { code: 'TD', label: '235 查德', value: '235' },
    { code: 'TF', label: '262 留尼旺', value: '262' },
    { code: 'TG', label: '228 多哥', value: '228' },
    { code: 'TH', label: '66 泰國', value: '66' },
    { code: 'TJ', label: '992 塔吉克', value: '992' },
    { code: 'TK', label: '690 托克勞', value: '690' },
    { code: 'TL', label: '670 東帝汶', value: '670' },
    { code: 'TM', label: '993 土庫曼', value: '993' },
    { code: 'TN', label: '216 突尼西亞', value: '216' },
    { code: 'TO', label: '676 東加', value: '676' },
    { code: 'TR', label: '90 土耳其', value: '90' },
    { code: 'TT', label: '1-868 千里達及托巴哥', value: '1868' },
    { code: 'TV', label: '688 吐瓦魯', value: '688' },
    { code: 'TW', label: '886 臺灣', value: '886' },
    { code: 'TZ', label: '255 坦尚尼亞', value: '255' },
    { code: 'UA', label: '380 烏克蘭', value: '380' },
    { code: 'UG', label: '256 烏干達', value: '256' },
    { code: 'US', label: '1 美國', value: '1', suggested: true },
    { code: 'UY', label: '598 烏拉圭', value: '598' },
    { code: 'UZ', label: '998 烏茲別克', value: '998' },
    { code: 'VA', label: '379 梵蒂岡', value: '379' },
    { code: 'VC', label: '1-784 聖文森及格瑞那丁', value: '1784' },
    { code: 'VE', label: '58 委內瑞拉', value: '58' },
    { code: 'VG', label: '1-284 英屬維京群島', value: '1284' },
    { code: 'VI', label: '1-340 美屬維京群島', value: '1340' },
    { code: 'VN', label: '84 越南', value: '84' },
    { code: 'VU', label: '678 萬那杜', value: '678' },
    { code: 'WF', label: '681 瓦利斯和富圖那', value: '681' },
    { code: 'WS', label: '685 薩摩亞', value: '685' },
    { code: 'XK', label: '383 科索沃', value: '383' },
    { code: 'YE', label: '967 葉門', value: '967' },
    { code: 'YT', label: '262 馬約特', value: '262' },
    { code: 'ZA', label: '27 南非', value: '27' },
    { code: 'ZM', label: '260 尚比亞', value: '260' },
    { code: 'ZW', label: '263 辛巴威', value: '263' },
];