import React, { useState, useRef, useEffect } from 'react';

import {
    Typography,
} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { Button, } from "@material-ui/core";
import Radio from '@material-ui/core/Radio';
import {
    apiBuyRechargeGomypay,
    apiPaymentGetLovecodeInfo,
    apiPaymentInfoPaynow,
    apiModifyUserInfo,
    gomypayAPI,
    handleAPICall,
    handleRespCode,
} from '../../service/api';
import {
    useLoveCode,
    email,
    personalInvoice,
    businessInvoice,
    CarrierTypeMember,
    CarrierTypePhone,
    CarrierTypeCDC,
    gomypayPayTypeCreditCard,
    gomypayPayTypeConvStorePaycode,
    gomypayPayTypeUnionPay
} from './Const';
import {
    isUbnError,
    isNameError,
    isEmailValid,
    isValidPhoneCarrier,
    isValidCDCCarrier,
    paynowValidName,
    validPhone
} from './isValid';
import { isValidPhoneNumber } from 'libphonenumber-js';

const useStyles = makeStyles((theme) => ({
    form: {
        fontSize: '.6rem',
    },
    text: {
        padding: '2px',
        color: 'black',
    },
    textInputColor: {
        color: 'black'
    },
    loveCode: {
        padding: '2px',
        color: '#007bff',
    },
    button: {
        lineHeight: '3',
        marginTop: '2rem',
        border: 'none',
        color: '#ffffff',
        backgroundColor: 'black',
        "&:hover": { color: '#ffffff', backgroundColor: 'black', },
    },
    placeholder: {
        color: '#cc0000',
    },
    helperText: {
        color: '#cc0000',
        height: '50px',
    },
    context: {
        marginTop: '1rem',
        color: 'black',
    },
}));
const GomypayForm = ({ id, payType, ...props }) => {
    const classes = useStyles();
    const carrierTypeDefaultValue = 1
    const [selectedValue, setSelectedValue] = useState(useLoveCode);
    const [selectedCarrierTypeValue, setCarrierTypeValue] = useState(carrierTypeDefaultValue);
    const [carrier, setCarrier] = useState('');
    const [originalName, setOriginalName] = useState('');
    const [originalEmail, setOriginalEmail] = useState('');
    const [originalPhone, setOriginalPhone] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userName, setUserName] = useState('');
    const [userPhone, setUserPhone] = useState('');
    const [buyer_ubn, setBuyerUbn] = useState('');
    const [buyer_name, setBuyerName] = useState('');
    const [use_love_code, setUseLoveCode] = useState(selectedValue === useLoveCode);
    const [inputDisable, setInputDisable] = useState(true);
    // const [isEmailRequired, setEmailRequired] = useState(false);
    const [helperTextUbn, setHelperTextUbn] = useState('')
    const [helperTextName, setHelperTextName] = useState('')
    const [loveCodeInfo, setLoveCodeInfo] = useState('');
    const [phoneCarrierError, setPhoneCarrierError] = useState(null);
    const [CDCCarrierError, setCDCCarrierError] = useState(null);
    const [phoneCarrierHelperText, setPhoneCarrierHelperText] = useState(null);
    const [CDCCarrierHelperText, setCDCCarrierHelperText] = useState(null);

    const handleChangeRadio = (e) => {
        const target = e.target.value
        if (target === useLoveCode) {
            setSelectedValue(e.target.value)

            setHelperTextUbn('')
            setHelperTextName('')
            setUseLoveCode(true)
            setInputDisable(true)
            // setUserEmail('')
            setBuyerName('');
            setBuyerUbn('');
            // setEmailRequired(false)
            setCarrierTypeValue(carrierTypeDefaultValue)
            setCarrier('')
            setPhoneCarrierHelperText('')
            setCDCCarrierHelperText('')
        } else if (target === personalInvoice) {
            setSelectedValue(e.target.value)

            setHelperTextUbn('')
            setHelperTextName('')
            setUseLoveCode(false)
            setInputDisable(false)
            setBuyerName('');
            setBuyerUbn('');
            // setEmailRequired(true)
            setCarrierTypeValue(carrierTypeDefaultValue)
            setCarrier('')
            setPhoneCarrierHelperText('')
            setCDCCarrierHelperText('')
        } else if (target === businessInvoice) {
            setSelectedValue(e.target.value)

            setUseLoveCode(false)
            setInputDisable(false)
            // setEmailRequired(true)
            setCarrierTypeValue(carrierTypeDefaultValue)
            setCarrier('')
            setPhoneCarrierHelperText('')
            setCDCCarrierHelperText('')
        }
    };
    const handleChangeCarrierType = (e) => {
        setCarrierTypeValue(parseInt(e.target.value))
        setCarrier('')
        setPhoneCarrierHelperText('')
        setCDCCarrierHelperText('')
    }
    useEffect(() => {
        isUbnError(buyer_ubn, buyer_name) ? setHelperTextUbn('買受人統一編號，純數字') : setHelperTextUbn('')
        if (!isNameError(buyer_ubn, buyer_name)) {
            setHelperTextName('')
        } else {
            if (buyer_name.length < 2 || buyer_name.length > 60) {
                setHelperTextName('發票抬頭長度限制 60 字元')
            } else {
                setHelperTextName('發票抬頭不可包含數字及特殊字元')
            }
        }
    }, [buyer_ubn, buyer_name])

    useEffect(() => {
        apiPaymentGetLovecodeInfo()
            .then((res) => res.data)
            .then((res) => {
                if (handleRespCode(res) === false) {
                    return
                } else {
                    setLoveCodeInfo(res.name + "(" + res.lovecode + ")")
                }
            })
            .catch((error) => handleAPICall(error))
    }, [])
    useEffect(() => {
        apiPaymentInfoPaynow()
            .then((res) => res.data)
            .then((resp) => {
                if (handleRespCode(resp) === false) {
                    return
                } else {
                    setOriginalPhone(resp.user_phone)
                    setOriginalName(resp.user_name)
                    setOriginalEmail(resp.user_email)
                    setUserPhone(resp.user_phone)
                    setUserName(resp.user_name)
                    setUserEmail(resp.user_email)
                }
            })
            .catch((error) => handleAPICall(error))
    }, [])

    const isValidPhone = () => {
        if (userPhone === "") {
            return true
        }
        return validPhone(userPhone)
    }
    const isUserInfoChanged = () => {
        return userPhone !== originalPhone || userName !== originalName || userEmail !== originalEmail
    }
    const modifyUserInfo = async () => {
        return await apiModifyUserInfo({
            "user_name": userName,
            "user_email": userEmail,
            "user_phone": userPhone,
        })
            .then((res) => res.data)
            .then((resp) => {
                return handleRespCode(resp)
            })
            .catch((error) => handleAPICall(error))
        // we don't handle error because modifyUserInfo is not the main purpose of purchasing
    };

    function validateForm() {
        // username, email, phone are required
        if (userName === "") {
            return false
        }
        if (!paynowValidName(userName)) {
            return false
        }
        if (userEmail === "") {
            return false
        }
        if (!isEmailValid(userEmail)) {
            return false
        }
        if (userPhone === "") {
            return false
        }
        if (!validPhone(userPhone)) {
            return false
        }

        if (selectedValue === useLoveCode) {
            return true
        } else if (selectedValue === personalInvoice){
            if (selectedCarrierTypeValue === CarrierTypeMember) {
                return true
            }
            if (selectedCarrierTypeValue === CarrierTypePhone) {
                if (carrier === "") {
                    return false
                }
                return isValidPhoneCarrier(carrier)
            }
            if (selectedCarrierTypeValue === CarrierTypeCDC) {
                if (carrier === "") {
                    return false
                }
                return isValidCDCCarrier(carrier)
            }
            return true
        } else if (selectedValue === businessInvoice) {
            if (buyer_ubn === "" || buyer_name === "") {
                return false
            }
            return !isUbnError(buyer_ubn, buyer_name) && !isNameError(buyer_ubn, buyer_name)
        }
    }

    const gomypay_form = useRef(null);
    const gomapy_form_send_type = useRef(null);
    const gomapy_form_pay_mode_no = useRef(null);
    const gomapy_form_customerId = useRef(null);
    const gomapy_form_order_no = useRef(null);
    const gomapy_form_amount = useRef(null);
    const gomapy_form_buyer_name = useRef(null);
    const gomapy_form_buyer_telm = useRef(null);
    const gomapy_form_buyer_email = useRef(null);
    const gomapy_form_buyer_memo = useRef(null);
    const gomapy_form_trans_code = useRef(null);
    const gomapy_form_trans_mode = useRef(null);
    const gomapy_form_installment = useRef(null);
    const gomapy_form_store_type = useRef(null);
    const gomapy_form_return_url = useRef(null);
    const gomapy_form_callback_url = useRef(null);
    const gomypayFormSubmit = (data) => {
        gomapy_form_send_type.current.value = data.Send_Type;
        gomapy_form_pay_mode_no.current.value = data.Pay_Mode_No;
        gomapy_form_customerId.current.value = data.CustomerId
        gomapy_form_order_no.current.value = data.Order_No;
        gomapy_form_amount.current.value = data.Amount;
        gomapy_form_buyer_name.current.value = data.Buyer_Name;
        gomapy_form_buyer_telm.current.value = data.Buyer_Telm;
        gomapy_form_buyer_email.current.value = data.Buyer_Mail;
        gomapy_form_buyer_memo.current.value = data.Buyer_Memo;
        gomapy_form_return_url.current.value = data.Return_url;
        gomapy_form_callback_url.current.value = data.Callback_Url;
        if (payType === gomypayPayTypeCreditCard) {
            gomapy_form_trans_code.current.value = data.TransCode;
            gomapy_form_trans_mode.current.value = data.TransMode;
            gomapy_form_installment.current.value = data.Installment;
        } else if (payType === gomypayPayTypeUnionPay) {
            gomapy_form_trans_code.current.value = data.TransCode;
        } else if (payType === gomypayPayTypeConvStorePaycode) {
            gomapy_form_store_type.current.value = data.StoreType;
        }
        gomypay_form.current.submit();
    }

    const onBuySubmit = async (recharge_id) => {
        // empty strings are invalid params, so we only send corresponding params to the server
        let params = {
            recharge_id,
            pay_type: payType,
            use_love_code,
        }
        if (!use_love_code) {
            params.user_email = userEmail;
            if (buyer_ubn !== '' || buyer_name !== '') {
                params.buyer_ubn = buyer_ubn
                params.buyer_name = buyer_name
            }
            if (selectedValue === personalInvoice) {
                params.carrier_type = selectedCarrierTypeValue
                params.carrier = carrier
            }
        }
        const callBuyAPI = async () => {
            return await apiBuyRechargeGomypay(params)
            .then((res) => res.data)
            .then((res) => {
                if (handleRespCode(res) === false) {
                    return
                } else {
                    return gomypayFormSubmit(res)
                }
            })
            .catch((error) => handleAPICall(error))
        }
        const hasChanged = isUserInfoChanged();
        if (hasChanged === true) {
            await modifyUserInfo()
                .then((res) => {
                    if (res !== true) {
                        return
                    }
                    return callBuyAPI()
                })
        } else {
            await callBuyAPI()
        }
    };
    return (
        <>
            <Typography className={classes.context}>
                請填入購買人資訊
            </Typography>
                <TextField
                    required={true}
                    margin="dense"
                    // style={{ height: '50px', marginLeft: '1px', marginTop: '10px' }}
                    InputProps={{
                        classes: {
                            input: classes.textInputColor,
                        },
                        style: {
                            paddingLeft:1
                        }
                    }}
                    id="userName"
                    label="購買人姓名(必填)"
                    placeholder="不可含特殊符號及數字"
                    type="name"
                    value={userName}
                    error={!paynowValidName(userName)}
                    fullWidth
                    helperText=""
                    onChange={(e) => setUserName(e.target.value)}
                 />
                 <TextField
                    required={true}
                    margin="dense"
                    // style={{ height: '50px', marginLeft: '1px', marginTop: '2px' }}
                    InputProps={{
                        classes: {
                            input: classes.textInputColor,
                        },
                        style: {
                            paddingLeft:1
                        }
                    }}
                    id="userEmail"
                    label="購買人Email(必填)"
                    placeholder=""
                    type={email}
                    value={userEmail}
                    error={!isEmailValid(userEmail)}
                    fullWidth
                    onChange={(e) => setUserEmail(e.target.value)}
                />
                <TextField
                    required={true}
                    margin="dense"
                    InputProps={{
                        classes: {
                            input: classes.textInputColor,
                        },
                        style: {
                            paddingLeft:1
                        }
                    }}
                    id="userPhone"
                    label="購買人電話(必填)"
                    placeholder=""
                    value={userPhone}
                    type="phone"
                    error={!isValidPhone(userPhone)}
                    fullWidth
                    onChange={(e) => setUserPhone(e.target.value)}
                />
            <Typography className={classes.context}>
                請選擇發票類型
            </Typography>
            <Radio
                checked={selectedValue === useLoveCode}
                onChange={handleChangeRadio}
                value={useLoveCode}
                color="default"
            />
            <span className={classes.text}>
               捐贈至
            </span>
            <span className={classes.loveCode}>{loveCodeInfo}</span><br />
            <Radio
                checked={selectedValue === personalInvoice}
                onChange={handleChangeRadio}
                value={personalInvoice}
                color="default"
            />
            <span className={classes.text}>
                個人電子發票
            </span><br />
            <Radio
                checked={selectedValue === businessInvoice}
                onChange={handleChangeRadio}
                label="businessInvoice"
                value={businessInvoice}
                color="default"
            />
            <span className={classes.text}>
                公司發票
            </span><br />
            <form className={classes.form} >
                 <Select
                    variant="outlined"
                    hidden={selectedValue !== personalInvoice}
                    color="primary"
                    className={classes.select}
                    fullWidth
                    label="載具類型"
                    native
                    defaultValue={carrierTypeDefaultValue}
                    value={selectedCarrierTypeValue}
                    onChange={handleChangeCarrierType}
                    style={{
                        height: '40px',
                        marginLeft: '-0.5px',
                        marginTop: '15px',
                        color: 'black',
                        paddingLeft: '0px',
                    }}
                    inputProps={{
                        name: 'age',
                        id: 'outlined-age-native-simple',
                    }}
                    SelectDisplayProps={{ classes: { paper: classes.select } }}
                    MenuProps={{
                        classes: { paper: classes.select },
                    }}
                >
                  <option value={1}>會員載具(用戶可自行歸戶)</option>
                  <option value={2}>手機載具條碼</option>
                  <option value={3}>自然人憑證</option>
                </Select>
                <TextField
                    hidden={selectedValue !== personalInvoice || selectedCarrierTypeValue !== CarrierTypePhone}
                    required={true}
                    style={{ height: '52px', marginTop: '0px', marginLeft: '1px' }}
                    InputProps={{
                        classes: {
                            input: classes.textInputColor
                        },
                        style: {
                            paddingLeft:1
                        }
                    }}
                    disabled={inputDisable}
                    margin="normal"
                    id="carrier_phone"
                    label="請輸入手機載具(必填)"
                    placeholder="範例：/ABC+123"
                    type="name"
                    value={carrier}
                    error={phoneCarrierError}
                    helperText={phoneCarrierHelperText}
                    fullWidth
                    FormHelperTextProps={{
                        className: classes.helperText
                    }}
                    onChange={(e) => {
                        setCarrier(e.target.value)
                        const t = e.target.value
                        if (t === "" || isValidPhoneCarrier(t)) {
                            setPhoneCarrierError(null)
                            setPhoneCarrierHelperText('')
                            return
                        }
                        setPhoneCarrierError(true)
                        setPhoneCarrierHelperText('範例：/ABC+123')
                    }}
                />
                <TextField
                    hidden={selectedValue !== personalInvoice || selectedCarrierTypeValue !== CarrierTypeCDC}
                    required={true}
                    style={{ height: '52px', marginTop: '0px', marginLeft: '1px' }}
                    InputProps={{
                        classes: {
                            input: classes.textInputColor
                        },
                        style: {
                            paddingLeft:1
                        }
                    }}
                    disabled={inputDisable}
                    margin="normal"
                    id="carrier_cdc"
                    label="請輸入自然人憑證(必填)"
                    placeholder="16碼英數字，前面兩碼為大寫英文字母"
                    type="name"
                    value={carrier}
                    error={CDCCarrierError}
                    helperText={CDCCarrierHelperText}
                    fullWidth
                    FormHelperTextProps={{
                        className: classes.helperText
                    }}
                    onChange={(e) => {
                        setCarrier(e.target.value)
                        const t = e.target.value
                        if (t === "" || isValidCDCCarrier(t)) {
                            setCDCCarrierError(null)
                            setCDCCarrierHelperText('')
                            return
                        }
                        setCDCCarrierError(true)
                        setCDCCarrierHelperText('16碼英數字，前面兩碼為大寫英文字母')
                    }}
                />
                <TextField
                    hidden={selectedValue !== businessInvoice}
                    required={true}
                    style={{ height: '50px', marginLeft: '1px' }}
                    InputProps={{
                        classes: {
                            input: classes.textInputColor
                        },
                        style: {
                            paddingLeft:1
                        }
                    }}
                    disabled={inputDisable}
                    margin="normal"
                    id="name"
                    label="請輸入統編(必填)"
                    placeholder="買受人統一編號，純數字"
                    type="name"
                    value={buyer_ubn}
                    error={isUbnError(buyer_ubn, buyer_name)}
                    helperText={helperTextUbn}
                    fullWidth
                    // variant="outlined"
                    FormHelperTextProps={{
                        className: classes.helperText
                    }}
                    onChange={(e) => setBuyerUbn(e.target.value)}
                />
                <TextField
                    hidden={selectedValue !== businessInvoice}
                    required={true}
                    style={{ height: '50px', marginLeft: '1px' }}
                    InputProps={{
                        classes: {
                            input: classes.textInputColor
                        },
                        style: {
                            paddingLeft:1
                        }
                    }}
                    disabled={inputDisable}
                    margin="normal"
                    id="title"
                    label="請輸入發票抬頭(必填)"
                    placeholder="發票抬頭長度限制 60 字元"
                    type="name"
                    value={buyer_name}
                    error={isNameError(buyer_ubn, buyer_name)}
                    helperText={helperTextName}
                    fullWidth
                    // variant="outlined"
                    FormHelperTextProps={{
                        className: classes.helperText
                    }}
                    onChange={(e) => setBuyerName(e.target.value)}
                />
            </form>

            <Button
                type="submit"
                onClick={() => onBuySubmit(id)}
                disabled={!validateForm()}
                fullWidth
                className={classes.button}
                variant="contained"
            >
                前往付款
            </Button>

            <form id="gomapy_form" ref={gomypay_form} name='gomypay' method='post' action={gomypayAPI}>
                <input type='hidden' ref={gomapy_form_send_type} id="Send_Type" name='Send_Type' value='' />
                <input type='hidden' ref={gomapy_form_pay_mode_no} id="Pay_Mode_No" name='Pay_Mode_No' value='' />
                <input type='hidden' ref={gomapy_form_customerId} id="CustomerId" name='CustomerId' value='' />
                <input type='hidden' ref={gomapy_form_order_no} id="Order_No" name='Order_No' value='' />
                <input type='hidden' ref={gomapy_form_amount} id="Amount" name='Amount' value='' />
                <input type='hidden' ref={gomapy_form_buyer_name} id="Buyer_Name" name='Buyer_Name' value='' />
                <input type='hidden' ref={gomapy_form_buyer_telm} id="Buyer_Telm" name='Buyer_Telm' value='' />
                <input type='hidden' ref={gomapy_form_buyer_email} id="Buyer_Mail" name='Buyer_Mail' value='' />
                <input type='hidden' ref={gomapy_form_buyer_memo} id="Buyer_Memo" name='Buyer_Memo' value='' />
                <input type='hidden' ref={gomapy_form_trans_code} id="TransCode" name='TransCode' value='' />
                <input type='hidden' ref={gomapy_form_trans_mode} id="TransMode" name='TransMode' value='' />
                <input type='hidden' ref={gomapy_form_installment} id="Installment" name='Installment' value='' />
                <input type='hidden' ref={gomapy_form_store_type} id="StoreType" name='StoreType' value='' />
                <input type='hidden' ref={gomapy_form_return_url} id="Return_url" name='Return_url' value='' />
                <input type='hidden' ref={gomapy_form_callback_url} id="Callback_Url" name='Callback_Url' value='' />
            </form>
        </>
    )
};

export default GomypayForm;
